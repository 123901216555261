export default {
	data: () => ({
		selectParentBox: undefined
	}),
	methods: {
		findParent(el, className) {
			if (el.classList.contains('box')) {
				return el
			}
			else if (el.parentElement) {
				return this.findParent(el.parentElement, className)
			}
			else {
				return null
			}
		},

		getSelectParentBox() {
			if (this.selectParentBox === undefined) {
				this.selectParentBox = this.findParent(this.$el, 'box')
			}

			return this.selectParentBox
		},

		onTableSelectOpen() {
			const parentBox = this.getSelectParentBox()
			if (parentBox) {
				parentBox.classList.add('hasSelectOpened')
			}
		},

		onTableSelectClose() {
			const parentBox = this.getSelectParentBox()
			if (parentBox) {
				parentBox.classList.remove('hasSelectOpened')
			}
		}
	}
}
